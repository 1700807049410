import { useEffect, useState } from "react";
import CV from "./components/CV/CV";
import Expertise from "./components/Expertise/Expertise";
import Home from "./components/Home/Home";
import { isIOS } from "react-device-detect";

function App() {

  const [isMobile, setIsMobile] = useState(false);
  const [isIOsState, setIsIOsState] = useState(false);
  const [load, setLoad] = useState(true);

  const isMobileTest = () => {
    try {
      document.createEvent('TouchEvent');
      return true;
    }
    catch(e) {
      return false;
    }
  }

  useEffect(()=> {
    
    setIsMobile(isMobileTest());
    setIsIOsState(isIOS);
    setLoad(false);

  },[]);

  return (
    load === false && <>
      <Home isMobile={isMobile}/>
      <Expertise/>
      <CV isIOsState={isIOsState} isMobile={isMobile}/>
    </>
  );
}

export default App;
