import React from 'react';

const Container = (props) => {

    return (
        <div id={props.anchor} style={{zIndex: 10, padding:"12px", backgroundColor:"#ffffff"}}>
            {props.children}
        </div>
    )
};

export default Container;