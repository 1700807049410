import { Backdrop, Grid, Hidden, IconButton } from "@mui/material";
import React from "react";
import "./BackdropDetails.css";
import CloseIcon from "@mui/icons-material/Close";

const BackdropDetails = (props) => {

  return (
    <Backdrop open={props.open} onClose={() => props.setOpen(false)} 
    sx={{
      zIndex:100,
      backdropFilter: "blur(5px)",
    }}>
      {props.data.length > 0 && (
        <div className="backdrop-main">
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-evenly",
            }}
          >
            <Grid item xl={11} xs={9} sx={{ marginBottom: "5vh" }}>
              <div className="summary">
                <p>
                  <b>{props.data[0].title}</b>
                </p>
                <p>
                  <i>{props.data[0].date}</i>
                </p>
                <p>
                  <i>{props.data[0].sub1}</i>
                </p>
                <p>{props.data[0].sub2}</p>
              </div>
            </Grid>
            <Grid
              item
              xl={1}
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              <IconButton onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "space-evenly",
            }}
          >
            <Grid item xl={6} md={6} xs={12} sx={{ width: "90%", paddingBottom:2 }}>
              <div className="text">
                <p>
                  <b>Summary:</b>
                </p>
                <p>{props.data[0].text}</p>
              </div>
            </Grid>
            <Hidden smDown><Grid item xl={1.5} md={1.5} sx={{ width: "90%" }}></Grid></Hidden>
            <Grid item xl={4.5} md={4.5} xs={12} sx={{ width: "90%" }}>
              <div className="more">
                {props.data[0].type === "job" && (
                  <p>
                    <b>Acomplished projects:</b>
                  </p>
                )}
                {props.data[0].type === "edu" && (
                  <p>
                    <b>Reserach:</b>
                  </p>
                )}
                {props.data[0].projects.map((project) => {
                  return (
                    <React.Fragment key={`project-${project.name}`}>
                      <p>
                        <i>{project.name}</i>
                      </p>
                      <p>{project.description}</p>
                      <div className="tag-container">
                        {project.tags.map((tag) => {
                          return <div key={`${project.name}-${tag}`} className="tech-tag">{tag}</div>;
                        })}
                      </div>
                      <br />
                    </React.Fragment>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Backdrop>
  );
};

export default BackdropDetails;
