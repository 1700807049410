export const cvdata = [
  {
    id: "shs4",
    type: "job",
    order: 1,
    title: "Siemens Healthineers",
    date: "05/2018 - Now",
    sub1: "Application Architect",
    sub2: "Analytical Services",
    text: "Gathered experience as an Application Architect in an Analytics Team within Siemens Healthineers (Laboratory Diagnostics Division). Learned to collect direct user feedback, translate this into Wireframes and UI Designs, to be able to develop applications that are from the user and for the user. Created an application together with a Customer in a Co-Creation setup. Led a team of five people as a Project Manager to integrate Microsoft PowerBI into the Service Organization. Gained a lot of knowledge about the Laboratory Diagnostics Business as well as the Healthcare Sector in general. ",
    projects: [
      {
        name: "1. Predictive Service App",
        description:
          "Angular and Node-based web app for proactive service support to reduce downtime for customers",
        tags: ["Angular", "NodeJS", "ASQL", "Cosmos", "PowerBI"],
      },
      {
        name: "2. Machine Data App (Customer Co Creation)",
        description:
          "Qlik-based customer-facing offering displaying machine data to selected customer chains to help the customer use their instruments more efficient created in a co-creation manner together with the customers",
        tags: ["QlikSense", "ASQL", "Cosmos"],
      },
      {
        name: "3. Assay Analytics",
        description:
          "React-based web applications showing the assay utilization of the customer labs to cut down unnecessary costs",
          tags: ["React", "ASQL", "PowerBI"],
      },
      {
        name: "4. Reagents & Instrument Forecast Platform",
        description:
          "HTML and JavaScript-based web application for collecting knowledge-based country forecasts each month to generate a solid SCM production forecast",
          tags: ["HTML", "JS", "Teradata"],
      },
      {
        name: "5. Global Installation Support App",
        description:
          "Dashboard displaying top-level management KPIs of global product fleet rollout to increase rollout speed",
          tags: ["SASViya", "Teradata"],
      },
    ],
  },
  {
    id: "shs3",
    type: "job",
    order: 2,
    title: "Siemens Healthineers",
    date: "05/2017 - 05/2018",
    sub1: "Working Student",
    sub2: "Analytical Services",
    text: "Supporting multiple Analytical Projects as a working student, as well as creating own applications. Learning a lot about databases, Python and front-end tools.",
    projects: [
      {
        name: "1. Self-Service Geocoding App",
        description:
          "React and Python-enabled web app for generating geocodes based on Google Geocoding API",
          tags: ["React", "Python", "Google Geocoding", "GeoJSON"],
      },
      {
        name: "2. Managment Dashboard summarizing Online Customer Interaction",
        description:
          "Dashboard that visualizes KPIs related to Online CUstomer Interaction",
          tags: ["SASViya", "Teradata"],
      },
    ],
  },
  {
    id: "shs2",
    type: "job",
    order: 3,
    title: "Siemens Medical Solutions US, New York",
    date: "11/2016 - 05/2017",
    sub1: "Intern",
    sub2: "Analytical Services",
    text:"Living abroad and supporting the American Analytics Team with creating a dashboard that summarizes the current state of the Remote Machine Connections (for remote log data transmissions)",
    projects: [
      {
        name: "1. Machine Remote Connection Summary",
        description:
          "Dashboard inidicating the Health of the Connection of machines sending machine data",
          tags: ["SASViya", "Teradata"],
      }
    ],
  },
  {
    id: "shs1",
    type: "job",
    order: 4,
    title: "Siemens Healthineers",
    date: "11/2013 - 11/2016",
    sub1: "Working Student",
    sub2: "Release Management",
    text:"Amongst other supportive tasks, helping the Release Management team with creating an application that enables them to do their work quicker and more efficient.",
    projects: [
      {
        name: "1. GUI for Release Management based on ClearCase (Legacy Software)",
        description:
          "A simple GUI based on C# for dispatching Release Control commands with ClearCase",
          tags: ["C#", "ClearCase"],
      }
    ],
  },
  {
    id: "datev",
    type: "job",
    order: 5,
    title: "Datev eG",
    date: "07/2012 - 09/2012",
    sub1: "Intern",
    sub2: "Product Management & Service",
    text:"Learned early on to communicate with customers being in the Product Management team of Datev eG as a Summer Intern.",
    projects: [
      {
        name: "1. Outbound Service Calls",
        description:
          "Direct customer contact by phone for running key account server checks",
          tags: ["MS Office Suite","Windows Server 2012"]
      }
    ],
  },
  {
    id: "master",
    type: "edu",
    order: 1,
    title: "Otto Friedrich Universität Bamberg",
    date: "10/2015 - 04/2018",
    sub1: "M.Sc.",
    sub2: "International Information Systems Management",
    text:"Deepend my knowledge of the conceptual level of IT systems. Learned to define Service Blueprints, as well as how to guide an enterprise through (IT-) Change Management. Created a Science-Based Maturity Model to assess the maturity of Predictive Maintenance Service Offerings in cooperation with Siemens Healthineers and a real-life Predictive Service Use-Case as a Master Thesis.",
    projects: [
      {
        name: "Master Thesis",
        description:
          "Toward a maturity model to assess the Maturity of a Condition-Based Maintenance Solution",
          tags: ["Predictive Maintenance","Maturity Model"]
      }
    ],
  },
  {
    id: "bachelor",
    type: "edu",
    order: 2,
    title: "FAU Erlangen-Nürnberg",
    date: "10/2013 - 04/2015",
    sub1: "B.A.",
    sub2: "Business and Economics /w Information Systems Focus",
    text: "Gained a solid understanding of economics as well as majoring in Business Informatics. Created a Bachelor Thesis about the early adoption of E-Mobility Services and the users' opinions in Social Media Networks using Sentinment Analysis.",
    projects: [
      {
        name: "Bachelor Thesis",
        description:
          "Usage of Social Media for the Assessment and Advancements of E-Mobility Service Offerings",
          tags: ["E-Mobility","Social Media","Sentiment Analysis"]
      }
    ],
  },
];
