import { Grid, Hidden } from "@mui/material";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import BackdropDetails from "../Backdrop/BackdropDetails";
import Container from "../Container";
import "./CV.css";
import { cvdata } from "./Data";
import img from "../../assets/click5.png";

function CV(props) {
  const [open, setOpen] = useState(false);
  const data = cvdata;
  const [dataDisplay, setDataDisplay] = useState([]);

  const onOpen = (id) => {
    let display = data.filter((f) => f.id === id);
    setDataDisplay(display);
    setOpen(true);
  };

  return (
    <>
      {ReactDOM.createPortal(
        <BackdropDetails open={open} setOpen={setOpen} data={dataDisplay} />,
        document.getElementById("backdrop")
      )}
      <Container anchor="cv-anchor">
        <Grid container className="cv" id="cv">
          <Grid item xl={4} lg={6} md={6} xs={12} sx={{ p: "20px" }}>
            <div className="cv-card">
              <div className="cv-card-header">
                <h3>Steffen Klima</h3>
                <a
                  href="https://www.linkedin.com/in/steffen-klima-863788120/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
              </div>
              <div className="contact">
                <p>Fürth, BY, Germany</p>
                <p><a href="mailto:steffen.klima@gmail.com">steffen.klima@gmail.com</a></p>
              </div>
              <div className="description">
                <p>
                  Application Architect with 7+ years experience at shaping,
                  designing and implementing internal and customer-facing web
                  applications using state of the art technology such as React
                  and NodeJS.
                </p>
                <p>
                  Proven ability to combine Requirements Engineering,
                  Wireframing, UX Design and Technical Implementation. Routined
                  in contributing in onsite and remote work environments as well
                  as having experience as a leading Project Manager.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xl={4} lg={6} md={6} xs={12} sx={{ p: "20px"  }}>
            <div className="cv-card">
              <div className="cv-card-header">
                <h3>Experience</h3>
              </div>
              {data
                .filter((f) => f.type === "job")
                .map((item, index) => {
                  return index === 0 ? (
                      <div key={item.id} className="time" onClick={() => onOpen(item.id)}>
                        <img src={img} alt="badge" className="badge"></img>
                        <Hidden lgDown>
                          <div className="time-range">
                            <p>
                              <b>{item.date}</b>
                            </p>
                          </div>
                        </Hidden>
                        <div className="stelle">
                          <p>
                            <b>{item.title}</b>
                          </p>
                          <Hidden lgUp>
                            <p>
                              <i>{item.date}</i>
                            </p>
                          </Hidden>
                          <p>
                            <i>{item.sub1}</i>
                          </p>
                          <p>{item.sub2}</p>
                        </div>
                      </div>
                  ) : (
                    <React.Fragment key={item.id}>
                      <div className="time" onClick={() => onOpen(item.id)}>
                        <Hidden lgDown>
                          <div className="time-range">
                            <p>
                              <b>{item.date}</b>
                            </p>
                          </div>
                        </Hidden>
                        <div className="stelle">
                          <p>
                            <b>{item.title}</b>
                          </p>
                          <Hidden lgUp>
                            <p>
                              <i>{item.date}</i>
                            </p>
                          </Hidden>
                          <p>
                            <i>{item.sub1}</i>
                          </p>
                          <p>{item.sub2}</p>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </Grid>
          <Grid item xl={4} lg={6} md={6} xs={12} sx={{ p: "20px"  }}>
            <div className="cv-card">
              <h3>Education</h3>
              {data
                .filter((f) => f.type === "edu")
                .map((item, index) => {
                  return ( 
                    index === 0 ? (
                    <div
                      key={item.id}
                      className="time"
                      onClick={() => onOpen(item.id)}
                    >
                      <img src={img} alt="badge" className="badge"></img>
                      <Hidden lgDown>
                        <div className="time-range">
                          <p>
                            <b>{item.date}</b>
                          </p>
                        </div>
                      </Hidden>
                      <div className="stelle">
                        <p>
                          <b>{item.title}</b>
                        </p>
                        <Hidden lgUp>
                          <p>
                            <i>{item.date}</i>
                          </p>
                        </Hidden>
                        <p>
                          <i>{item.sub1}</i>
                        </p>
                        <p>{item.sub2}</p>
                      </div>
                    </div>) : (
                      <div
                      key={item.id}
                      className="time"
                      onClick={() => onOpen(item.id)}
                    >
                      <Hidden lgDown>
                        <div className="time-range">
                          <p>
                            <b>{item.date}</b>
                          </p>
                        </div>
                      </Hidden>
                      <div className="stelle">
                        <p>
                          <b>{item.title}</b>
                        </p>
                        <Hidden lgUp>
                          <p>
                            <i>{item.date}</i>
                          </p>
                        </Hidden>
                        <p>
                          <i>{item.sub1}</i>
                        </p>
                        <p>{item.sub2}</p>
                      </div>
                    </div>
                    )
                  );
                })}
            </div>
          </Grid>
          {props.isMobile === true && props.isIOsState === true ? (
            <></>
          ) : (
            <Grid item xl={4} lg={6} md={6} xs={12} sx={{ p: "20px"  }}>
              <div className="cv-img"></div>
            </Grid>
          )}
          <Grid item xl={4} lg={6} md={6} xs={12} sx={{ p: "20px"  }}>
            <div className="cv-card">
              <h3>Skills</h3>
              <div className="time-noclick">
                <p>
                  <b>Frontend</b>
                </p>
                <div className="stelle">
                  <p>
                    <b>React, Angular</b> (+3 yrs.)
                  </p>
                  <p>
                    <b>HTML, CSS, JS</b> (+5 yrs.)
                  </p>
                  <p>
                    <b>PowerBI, Qlik</b> (+8 yrs.)
                  </p>
                </div>
              </div>
              <div className="time-noclick">
                <p>
                  <b>Backend</b>
                </p>
                <div className="stelle">
                  <p>
                    <b>Nodejs, Express</b> (+3 yrs.)
                  </p>
                  <p>
                    <b>ASQL DB</b> (+5 yrs.)
                  </p>
                  <p>
                    <b>Teradata</b> (+7 yrs.)
                  </p>
                  <p>
                    <b>Blob, Parquet</b> (+5 yrs.)
                  </p>
                  <p>
                    <b>Cosmos DB</b> (+3 yrs.)
                  </p>
                </div>
              </div>
              <div className="time-noclick">
                <p>
                  <b>More Skills</b>
                </p>
                <div className="stelle">
                  <p>
                    <b>Git</b> (+5 yrs.)
                  </p>
                  <p>
                    <b>PM</b> (+2 yrs.)
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xl={4} lg={6} md={6} xs={12} sx={{ p: "20px" }}>
            <div className="cv-card">
              <h3>Languages</h3>
              <div className="time-noclick">
                <p>
                  <b>German</b>
                </p>
                <div className="stelle">
                  <p>Mothertongue</p>
                </div>
              </div>
              <div className="time-noclick">
                <p>
                  <b>English</b>
                </p>
                <div className="stelle">
                  <p>Bilingual proficiency</p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CV;
