import React, {useEffect} from 'react';
import Container from "../Container";
import "./Expertise.css";

function Expertise() {

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      // Loop over the entries
      entries.forEach(entry => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          entry.target.classList.add('shaker');
        }
        else {
          entry.target.classList.remove('shaker');
        }
      });
    });
    
    observer.observe(document.getElementById('shaker'));
  }, []);
  

  return (
    <Container anchor="expertise-anchor">
      <div className="intro">
          <p className="intro-p">
          Hi! <span id="shaker">👋</span> I'm Steffen Klima. I am an <span className="tag">Application Architect</span> & 
          a <span className="tag">Web Developer</span> based in Fürth, BY ☘️
          designing and implementing internal & customer facing web applications 👨‍💻.
          Find out more about me below.<br/> <span style={{cursor: "pointer"}} onClick={() => {document.getElementById('cv-anchor').scrollIntoView({behavior:"smooth"})}}>🔎</span>
          </p>
      </div>
    </Container>
  );
}

export default Expertise;
